@import 'node_modules/@teliads/components/foundations/spacing/variables.scss';
@import 'node_modules/@teliads/components/foundations/colors/variables.scss';
@import 'node_modules/@teliads/components/foundations/typography/variables.scss';
@import '@teliads/components/foundations/focus/mixins';
@import '@teliads/components/foundations/borders/variables';
@import '@teliads/components/foundations/breakpoints/mixins';
@import 'styles/breakpoints.scss';

.websites-nav-link {
  display: flex;
  align-items: center;
  color: $telia-black;

  &__text {
    display: none !important;
    @include telia-breakpoint('md') {
      display: block !important;
    }
  }

  &:hover {
    color: $telia-purple-700;
  }

  &__link {
    &:focus-visible {
      @include telia-focus-outline();
    }
  }

  &__icon {
    color: $telia-black;
  }

  &__divider {
    border-left: 1px solid #ccc;
    height: $telia-spacing-32;
    align-self: center;
    margin-left: $telia-spacing-16;
    margin-right: $telia-spacing-4;
  }

  &--desktop {
    margin-right: 3rem;
    position: absolute;
    top: 0;
    right: 3rem;

    &__divider {
      margin-left: $telia-spacing-8;
      margin-right: $telia-spacing-8;
    }
  }
}
