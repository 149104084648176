/* Heading */
@font-face {
  font-family: 'TeliaSansHeading';
  src: url('../public/fonts/TeliaSansHeading-Heading.woff2') format('woff2');
}

/* Normal aka Regular aka 400 */
@font-face {
  font-family: 'TeliaSans';
  src: url('../public/fonts/TeliaSans-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

/* Medium aka 500 */
@font-face {
  font-family: 'TeliaSans';
  src: url('../public/fonts/TeliaSans-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}

/* Bold aka 700 */
@font-face {
  font-family: 'TeliaSans';
  src: url('../public/fonts/TeliaSans-Bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
}
