@import 'node_modules/@teliads/components/foundations/spacing/variables.scss';
@import 'node_modules/@teliads/components/foundations/colors/variables.scss';
@import 'node_modules/@teliads/components/foundations/shadows/mixins.scss';
@import 'node_modules/@teliads/components/foundations/grid/mixins.scss';
@import 'node_modules/@teliads/components/foundations/typography/mixins.scss';
@import 'styles/mixins.scss';

.nav-teaser {
  &__root-link {
    padding-bottom: $telia-spacing-12;
  }

  &__content {
    border-left: 1px solid purple;
    padding: 0 0 $telia-spacing-4 $telia-spacing-24;
    display: flex;
    width: calc($telia-spacing-96 * 3 + $telia-spacing-24);
    max-height: 370px;
    flex-direction: column;
    gap: 14px;

    &-wrapper {
      display: flex;
      justify-content: flex-end;
      max-height: 90%;
    }

    &--image {
      width: 100%;
      aspect-ratio: 2/3;
      height: 58%;
      border-radius: $telia-spacing-16;
      object-fit: cover;
    }

    &--heading {
      @include telia-title-100();
      @include line-clamp(2);
    }

    &--cta {
      display: flex;
      align-items: flex-end;
      width: fit-content;
      gap: $telia-spacing-4;
      color: $telia-purple-700;

      &:hover {
        background-color: $telia-purple-100;
        border-radius: $telia-spacing-4;
        cursor: pointer;
      }
    }
  }
}
