@import 'node_modules/@teliads/components/foundations/colors/variables.scss';
@import 'node_modules/@teliads/components/foundations/breakpoints/mixins.scss';
@import '../../scss/NavItemExtensions.scss';
@import '../../scss/extensions.scss';

.nav-item-button {
  color: $telia-purple-850;
  height: 100%;
  background: none;
  border: none;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: $telia-spacing-4;
  @extend %responsive-font-size;

  &--selected {
    @extend %nav-item-selected-state;

    svg {
      transform: rotate(180deg);
    }
  }

  &::after {
    @extend %nav-item-bottom-line;
  }

  &--primary {
    @extend %nav-item-primary;
  }

  &--secondary {
    @extend %nav-item-secondary;
  }

  &:active {
    color: $telia-purple-600;
  }

  &:hover {
    @extend %nav-item-hover-state;
  }

  &:focus-visible {
    @extend %nav-item-focus-visible-state;
  }
}
