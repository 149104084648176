@import '@teliads/components/foundations/spacing/variables';
@import '@teliads/components/foundations/colors/variables';
@import '@teliads/components/foundations/borders/mixins';

.accordion-content {
  display: flex;
  gap: $telia-spacing-12;
  padding: $telia-spacing-16 0;
  align-items: center;

  @include telia-border(gray-200, xs, bottom);

  &:last-child {
    border: none;
    padding-bottom: 0;
  }
}
