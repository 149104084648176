@import '@teliads/components/foundations/spacing/variables';
@import '@teliads/components/foundations/borders/mixins';
@import '@teliads/components/foundations/breakpoints/variables';

$paragraph-max-width: 700px;

.richtext-element {
  width: 100%;
  margin: $telia-spacing-24 0;
  display: block;

  &__phone-number {
    width: 50%;
    display: block;
    min-width: 130px;
  }
}

.richtext-element-iframe {
  aspect-ratio: 16 / 9;
  height: 100%;
  width: 100%;
  border: none;
}

.richtext-paragraph {
  max-width: $paragraph-max-width;
}

.richtext-button-container {
  margin: $telia-spacing-16 $telia-spacing-16 $telia-spacing-16 0;
  display: inline-block;

  @media (max-width: $telia-breakpoint-small) {
    display: block;
  }
}
