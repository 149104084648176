@import '@teliads/components/foundations/spacing/variables';
@import '@teliads/components/foundations/borders/mixins';
@import '@teliads/components/foundations/breakpoints/variables';
@import '@teliads/components/foundations/typography/variables';
@import '@teliads/components/foundations/colors/variables';
@import '@teliads/components/foundations/breakpoints/mixins';

.datablocks-widget {
  overflow: auto;
  width: 100%;
  font-family: $telia-typography-telia-sans-font-family !important;

  &__wrapper {
    margin: $telia-spacing-32 0;
    display: flex;
    flex-direction: column;
    gap: $telia-spacing-24;
  }

  .mf-widget {
    width: 100% !important;
  }

  table {
    border-collapse: collapse;
    border-spacing: $telia-spacing-8 $telia-spacing-4;
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    width: 100%;
    vertical-align: baseline;
    word-break: normal;
  }

  thead {
    border-bottom: 1px solid $telia-purple-600;
  }

  tbody tr:hover {
    color: $telia-purple-700;
  }

  tbody tr:nth-child(2n + 1) {
    background-color: $telia-gray-50;
  }

  th {
    padding: $telia-spacing-4 $telia-spacing-8;
  }

  td {
    border-bottom: 1px solid $telia-gray-100;
    padding: $telia-spacing-8 $telia-spacing-8 $telia-spacing-8 0;
    text-align: center;
  }
}
