@import 'node_modules/@teliads/components/foundations/spacing/tokens.scss';
@import 'node_modules/@teliads/components/foundations/colors/variables.scss';
@import 'node_modules/@teliads/components/foundations/colors/mixins.scss';
@import 'node_modules/@teliads/components/foundations/borders/variables.scss';
@import 'node_modules/@teliads/components/foundations/typography/mixins.scss';
@import 'node_modules/@teliads/components/foundations/focus/mixins.scss';
@import 'node_modules/@teliads/components/foundations/tokens/dynamic-rem.scss';

.nav-link {
  @include telia-color('purple-850');
  @include telia-paragraph-100();
  @include telia-typography('teliasans', 'medium');
  cursor: pointer;
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: $telia-spacing-16 0;

  @include telia-breakpoint('lg') {
    padding: unset;
    display: inline-flex;
  }

  &__text {
    overflow-wrap: anywhere;
  }

  &:hover {
    @include telia-color('purple-700');
  }

  &:active {
    @include telia-color('purple-600');
  }

  &:focus {
    @include telia-focus-outline(0);
  }

  &:focus:not(:focus-visible) {
    outline: 0;
  }

  &:hover,
  &:active {
    text-decoration: underline;
  }

  &--link {
    @include telia-color('purple-700');
    @include telia-typography('teliasans', 'bold');

    &:visited {
      @include telia-bg-color('transparent');
    }

    &:hover,
    &:active {
      @include telia-bg-color('purple-100');
    }
  }

  &--level-1 {
    @include telia-color('purple-850');
    @include telia-title-100();
  }

  &__icon {
    margin-left: $telia-spacing-4;
  }
}
