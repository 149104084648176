@import 'node_modules/@teliads/components/foundations/colors/variables.scss';
@import 'node_modules/@teliads/components/foundations/spacing/variables.scss';
@import 'node_modules/@teliads/components/foundations/borders/variables.scss';
@import 'node_modules/@teliads/components/foundations/shadows/mixins.scss';
@import 'node_modules/@teliads/components/foundations/typography/mixins.scss';
@import 'node_modules/@teliads/components/foundations/typography/variables.scss';
@import '../../scss/NavItemExtensions.scss';

.search-dropdown {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: calc($telia-spacing-48 + $telia-spacing-8);
  left: 0;
  z-index: 1001;
  background-color: $telia-white;
  width: 100%;
  margin-top: $telia-spacing-12;

  @include telia-breakpoint('lg') {
    @include telia-shadow(md);
    gap: $telia-spacing-8;
    margin-top: 0;
    padding: $telia-spacing-24 $telia-spacing-16;
    border-radius: $telia-border-radius-8;
  }

  & > li {
    list-style-type: none;
  }

  &__title {
    display: none;

    @include telia-breakpoint('lg') {
      display: unset;
    }

    @include telia-additional-100();
    color: $telia-transparent-black-600;

    &--error {
      color: $telia-purple-850;
    }
  }

  &__item {
    font-weight: $telia-typography-weight-medium;
    white-space: normal;
  }

  &__line-break {
    width: 100%;
    margin: $telia-spacing-16 0;
    background-color: $telia-transparent-black-150;
    height: 1px;
  }
}
