@import 'node_modules/@teliads/components/foundations/spacing/variables.scss';
@import 'node_modules/@teliads/components/foundations/colors/variables.scss';
@import 'node_modules/@teliads/components/foundations/shadows/mixins.scss';
@import 'node_modules/@teliads/components/foundations/grid/mixins.scss';

.mega-menu {
  @include telia-shadow('sm');
  padding-top: $telia-spacing-24;
  padding-bottom: $telia-spacing-8;
  background-color: $telia-gray-50;
  display: none;
  position: fixed;
  width: 100%;
  overflow: auto;

  &--open {
    display: block;
  }

  &__grid-wrapper {
    display: grid;
    grid-template-columns: 7fr 3fr;
  }

  &__row {
    display: flex;
    justify-content: flex-start;
    gap: $telia-spacing-32;
  }

  &__root-link {
    padding-bottom: $telia-spacing-12;
  }
}
