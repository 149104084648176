@import 'node_modules/@teliads/components/foundations/spacing/variables.scss';
@import 'node_modules/@teliads/components/foundations/colors/variables.scss';
@import 'node_modules/@teliads/components/foundations/typography/variables.scss';
@import 'node_modules/@teliads/components/foundations/borders/mixins.scss';
@import 'node_modules/@teliads/components/foundations/grid/mixins.scss';
@import '../../scss/variables.scss';

.header {
  width: 100%;
  font-family: $telia-typography-telia-sans-font-family;
  background-color: $telia-white;
  box-shadow: inset 0px -2px 0px 0px $telia-gray-50;

  &--sticky {
    position: sticky;
    top: 0;
    left: 0;
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
}

.navigation {
  @include telia-grid;
  display: flex;
  align-items: center;
  gap: $telia-spacing-48;
  white-space: nowrap;
  position: relative;

  &__list {
    display: flex;
  }

  &__item {
    display: flex;
    align-items: center;
    position: relative;
  }
}
