@import 'node_modules/@teliads/components/foundations/spacing/variables.scss';
@import 'node_modules/@teliads/components/foundations/colors/variables.scss';
@import 'node_modules/@teliads/components/foundations/typography/variables.scss';
@import 'node_modules/@teliads/components/foundations/typography/mixins.scss';
@import 'node_modules/@teliads/components/foundations/focus/mixins.scss';
@import '../scss/variables.scss';

%nav-item-bottom-line::after {
  visibility: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  content: '';
  width: 100%;
  height: $telia-spacing-2;
  background-color: $telia-transparent-black-150;
}

%nav-item-primary {
  @include telia-paragraph-100();

  padding: $telia-spacing-24 $telia-spacing-12;
  font-weight: $telia-typography-weight-medium;

  @media screen and (min-width: $breakpoint-xl) {
    padding: $telia-spacing-24 $telia-spacing-16;
  }
}

%nav-item-secondary {
  @include telia-additional-100();

  padding: $telia-spacing-12;
  font-weight: $telia-typography-weight-medium;
}

%nav-item-hover-state {
  color: $telia-purple-700;

  &::after {
    visibility: visible;
  }
}

%nav-item-selected-state {
  color: $telia-purple-600;

  &::after {
    visibility: visible;
    background-color: $telia-purple-600;
  }
}

%nav-item-focus-visible-state {
  @include telia-focus-outline();
}
