@import '@teliads/components/foundations/typography/mixins';
@import '@teliads/components/foundations/colors/variables';
@import '@teliads/components/foundations/focus/mixins';
@import 'styles/animation';

.expansion-panel {
  &--expanded {
    & > .expansion-panel__header {
      font-weight: 500;

      & > .expansion-panel__expand-icon {
        transform: rotate(180deg);
      }
    }
  }

  &__header {
    @include telia-paragraph-100;

    background: transparent;
    border: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 1;
    padding: 0;
    width: 100%;
    cursor: pointer;
    color: $telia-black;

    &:focus-visible {
      @include telia-focus-outline();
    }
  }

  &__expand-icon {
    transition: transform $animation-duration $transition-timing-function;
  }

  &__content {
    max-height: 0;
    overflow: hidden;

    &--visible {
      visibility: visible;
    }

    &--hidden {
      visibility: hidden;
    }
  }
}
