@import 'node_modules/@teliads/components/foundations/spacing/variables.scss';
@import 'node_modules/@teliads/components/foundations/colors/variables.scss';
@import 'node_modules/@teliads/components/foundations/typography/mixins.scss';
@import 'node_modules/@teliads/components/foundations/typography/tokens.scss';
@import 'node_modules/@teliads/components/foundations/grid/mixins.scss';

.section {
  display: inline-block;
  margin-bottom: $telia-spacing-32;

  &__heading {
    @include telia-additional-100();
    margin: 0;
    padding: $telia-spacing-4 0;
    color: $telia-typography-color-medium;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: $telia-spacing-8;
  }
}
