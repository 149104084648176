@import '@teliads/components/foundations/spacing/variables';
@import '@teliads/components/foundations/breakpoints/mixins';
@import '@teliads/components/foundations/colors/variables';

.calendar {
  &__button {
    display: inline-flex;
    gap: $telia-spacing-4;
    color: $telia-black;
    text-decoration: none;
    border: 1px solid $telia-purple-600;
    justify-content: center;
    border-radius: $telia-spacing-96;
    align-items: center;
    padding: $telia-spacing-12;

    &:hover {
      background-color: $telia-purple-100;
    }
  }
}
