@import '@teliads/components/foundations/spacing/variables';
@import '@teliads/components/foundations/colors/variables';
@import '@teliads/components/foundations/breakpoints/mixins';

.table-wrapper {
  overflow: auto;
  max-width: 100%;

  .table {
    border-collapse: collapse;
    border-spacing: 0;
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font-family: inherit;
    vertical-align: baseline;
    width: 100%;
    word-break: normal;

    @include telia-breakpoint('xl') {
      table-layout: fixed;
    }

    &__table-row {
      border-bottom: solid $telia-gray-300 1px;

      th {
        padding: $telia-spacing-8 $telia-spacing-12 $telia-spacing-8 $telia-spacing-12;
        text-align: left;
        min-width: 125px;
        font-weight: normal;
      }

      td {
        padding: $telia-spacing-16 $telia-spacing-12;
        text-align: left;
      }
    }
  }
}
