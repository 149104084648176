@import '@teliads/components/foundations/spacing/variables';
@import '@teliads/components/foundations/breakpoints/mixins';
@import '@teliads/components/foundations/colors/variables';

.calendar {
  display: grid;
  grid-template-columns: 20% auto;
  grid-template-areas:
    'top top1'
    'mid mid'
    'bottom bottom';
  gap: $telia-spacing-16;
  margin-top: $telia-spacing-32;
  margin-bottom: $telia-spacing-12;

  @include telia-breakpoint('md') {
    grid-template-columns: 13% 57% auto;
    grid-template-areas:
      'top top1 top2'
      'mid mid1 mid2';
    margin-top: $telia-spacing-64;
    margin-bottom: $telia-spacing-24;
  }

  &__date {
    display: flex;
    flex-direction: column;
  }

  &__drawer {
    display: none;

    &--active {
      display: flex;
      flex-direction: column;
      gap: $telia-spacing-8;
    }

    &-button--hidden {
      @include telia-breakpoint('md') {
        display: none;
      }
    }
  }

  &__description {
    display: flex;
    flex-direction: column;
    gap: $telia-spacing-12;
  }

  &__buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: $telia-spacing-24;
    align-items: flex-start;
    flex-wrap: wrap;
    grid-area: mid;

    @include telia-breakpoint('md') {
      grid-area: top2;
      flex-direction: column;
    }
    @include telia-breakpoint('xl') {
      flex-direction: row;
    }
  }
}

.drawer-container {
  grid-area: bottom;
  margin-bottom: $telia-spacing-12;

  @include telia-breakpoint('md') {
    grid-area: mid1;
    display: block;
  }
}

.drawer {
  &__paragraph {
    display: block;

    @include telia-breakpoint('md') {
      display: none;
    }
  }
}

.description {
  &__paragraph {
    display: none;

    @include telia-breakpoint('md') {
      display: block;
    }
  }
}
