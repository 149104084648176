.the-header {
  position: sticky;
  top: 0;
  z-index: 999;

  transition: all 0.36s ease;

  &.hidden {
    @extend .the-header;
    transform: translateY(var(--languageBarHeight));
  }
}
