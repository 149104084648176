@import 'node_modules/@teliads/components/foundations/spacing/variables.scss';
@import 'node_modules/@teliads/components/foundations/borders/mixins.scss';
@import 'node_modules/@teliads/components/foundations/borders/variables.scss';
@import 'node_modules/@teliads/components/foundations/focus/mixins.scss';
@import '../../scss/NavItemExtensions.scss';

.search {
  display: flex;
  justify-content: flex-end;
  position: relative;
  width: 100%;
  height: 100%;

  &--header {
    &::before {
      background: linear-gradient(
        270deg,
        rgba(255, 255, 255, 1) 11%,
        rgba(255, 255, 255, 0.7959777661064426) 43%,
        rgba(255, 255, 255, 0) 100%
      );
      content: '';
      height: 100%;
      left: -60px;
      position: absolute;
      width: 60px;
    }
  }

  &__input {
    padding: $telia-spacing-12 $telia-spacing-80 $telia-spacing-12 $telia-spacing-8;
    border-radius: $telia-border-radius-2;
    width: 0%;
    height: 100%;
    font-size: $telia-spacing-16;
    @include telia-border(transparent-black-300, xs, all);

    &:focus-visible {
      @include telia-focus-outline($offset: -3px);
    }

    &--error {
      border-color: $telia-red-600;
    }

    &--full-width {
      width: 100%;
    }
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    visibility: hidden;
    position: absolute;
    right: $telia-spacing-16;
    top: 50%;
    transform: translateY(-50%);
    color: $telia-purple-700;
    background: none;
    border: none;
    padding: $telia-spacing-4;
    cursor: pointer;

    &:focus-visible {
      @extend %nav-item-focus-visible-state;
    }

    @extend %nav-item-hover-state;
  }

  &__button--remove {
    visibility: visible;
    right: $telia-spacing-48;
  }

  &__button--submit {
    visibility: visible;
  }
}
