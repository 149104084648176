@import 'node_modules/@teliads/components/foundations/spacing/variables.scss';
@import 'node_modules/@teliads/components/foundations/colors/variables.scss';
@import 'node_modules/@teliads/components/foundations/grid/mixins.scss';
@import '../../scss/extensions.scss';

.language-switcher-wrapper {
  background-color: $telia-gray-50;
  width: 100%;
}

.language-switcher {
  @include telia-grid;
  display: flex;
  justify-content: flex-end;
  padding: 0 $telia-spacing-48;

  &__language {
    position: relative;
    height: 100%;
    @extend %responsive-font-size;
  }
}
