@import '@teliads/components/foundations/spacing/variables';
@import '@teliads/components/foundations/colors/variables';
@import '@teliads/components/foundations/borders/mixins';
@import 'styles/breakpoints.scss';
@import 'styles/mixins.scss';

.board-member-card {
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: $telia-spacing-32;
  text-decoration: none;
  color: $telia-black;

  &:last-child {
    margin-bottom: $telia-spacing-24;
  }

  @include telia-breakpoint('md') {
    width: calc(50% - $telia-spacing-24);
    margin-right: $telia-spacing-24;
  }

  @include telia-breakpoint('lg') {
    width: calc(100% / 3 - $telia-spacing-24);
  }

  &:hover {
    cursor: pointer;
    transition: transform 0.2s;
    transform: scale(1.05);
  }

  &__image-container {
    flex: 7;
    height: 100%;
  }

  &__image {
    aspect-ratio: 2/3;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: $telia-border-radius-8;

    &--hidden {
      display: none;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    padding: $telia-spacing-12 0 0 $telia-spacing-4;
  }
}
