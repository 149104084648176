@import 'node_modules/@teliads/components/foundations/typography/mixins.scss';
@import '../scss/variables.scss';

%responsive-font-size {
  @include telia-static-type(1.4rem);

  @media screen and (min-width: $breakpoint-xl) {
    @include telia-static-type(1.6rem);
  }
}
