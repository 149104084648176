@import 'node_modules/@teliads/components/foundations/colors/variables.scss';
@import 'node_modules/@teliads/components/foundations/spacing/variables.scss';
@import 'node_modules/@teliads/components/foundations/breakpoints/mixins.scss';
@import '../../scss/NavItemExtensions.scss';
@import '../../scss/extensions.scss';

.nav-item-link {
  color: $telia-purple-850;
  height: 100%;
  text-decoration: none;
  cursor: pointer;
  @extend %responsive-font-size;

  &::after {
    @extend %nav-item-bottom-line;
  }

  &--primary {
    @extend %nav-item-primary;
  }

  &--secondary {
    @extend %nav-item-secondary;
  }

  &--active {
    color: $telia-purple-600;

    &::after {
      visibility: visible;
      background-color: $telia-purple-600;
    }
  }

  &:active {
    color: $telia-purple-600;
  }

  &:hover {
    @extend %nav-item-hover-state;
  }

  &:focus-visible {
    @extend %nav-item-focus-visible-state;
  }
}

.nav-item-content {
  display: flex;
  align-items: center;
}

.nav-item-spacer {
  margin-right: $telia-spacing-2;
}